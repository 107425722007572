.logo {
  width: 200px;
  margin-bottom: -50px;
}

.logo:hover {
  opacity: .7
}

.navPad {
  align-self: center;
}

.navLink {
  text-decoration: none;
  color: #2a2b23;
  transition: all 0.2s ease-in-out 0s;
}

.burgerLink {
  text-decoration: none;
  color: #adafa1;
  transition: all 0.2s ease-in-out 0s;
}

.burgerLink:hover {
  color: white;
  transition: all 0.2s ease-in-out 0s;
  text-decoration: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 1rem;
  top: 1rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.navLink:hover, .navLink:focus {
  color: #adafa1;
  transition: all 0.2s ease-in-out 0s;
}
