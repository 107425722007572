.about {
  margin-bottom: 5em;
  min-height:61vh;
}

.aboutTitle {
  padding-top: 1em;
  margin-bottom: 2em;
  color: #2a2b23;
}

.imageColumn {
  margin-bottom: 2em;
}

.summaryText {
  margin-top: 2em;
  margin-bottom: 2em;
  color: #2a2b23;
}

.mountainView {
  height: 35vh;
  background-image: url("../images/mountainView.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.people {
  height: 35vh;
  background-image: url("../images/people.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.library {
  height: 35vh;
  background-image: url("../images/libraryCrop.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mountainView:hover, .people:hover, .library:hover {
  opacity: .75;
  cursor: pointer;
}

.aboutLink {
  color: #2a2b23;
  border: 1px solid #75787c;
  font-size: 18px;
  padding: 10px;
  transition: background .4s;
  text-decoration: none;
}

.aboutLink:hover {
  text-decoration: none;
  color:  #f7faff;
  background-color: #75787c;
}
