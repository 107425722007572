.contactTitle {
  padding-top: 1em;
}

.contactImage {
  margin-top: 4rem;
  width: 100%;
}

.emailLink {
  color: #3772ce;
  transition: all 0.2s ease-in-out 0s;
}

.emailLink:hover {
  cursor: pointer;
}

.contactText {
  font-size: 20px;
  text-align: left;
  font-family: sans-serif;
  font-style: italic;
  margin-top: 2em;
  margin-bottom: 2.5em;
}
