.footer-main {
  padding-top: 2em;
  background-color: #333333;
  min-height: 200px;
  width: 100%;
}

.copyright {
  min-height: 60px;
  background-color: #2a2b23;
  padding-top: 20px;
  color: #616366;
}

.logoFooter {
  width: 130px;
  margin-bottom: -.75em
}

.logoFooter:hover {
  opacity: .7;
}

.facebookIcon, .twitterIcon, .instagramIcon {
  width: 2em;
  margin-left: .75em;
  margin-right: .75em;
  margin-bottom: .75em;
  margin-top: .75em;
  opacity: .5;
  transition: all 0.2s ease-in-out 0s;
}

.facebookIcon:hover, .twitterIcon:hover, .instagramIcon:hover {
  opacity: 1;
  cursor: pointer;
  width: 2.5em;
  margin-left: .5em;
  margin-right: .5em;
  margin-bottom: .5em;
  margin-top: .5em;
  transition: all 0.2s ease-in-out 0s;
}

.footerText, .footerLink {
  margin-bottom: 1em;
  color: #616366;
}

.footerLink:hover {
  text-decoration: none;
  color: #f7faff;
  cursor: pointer;
}
