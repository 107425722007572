.staffImage {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
}

.staffCard {
  padding: 20px;
  padding-top: 40px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin-bottom: 5em;
  color: #2a2b23;
  text-align: left;
}
