.backgroundImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bigLogo {
  width: 40%;
}

.backgroundImage {
  background-image: url("../images/morningViewCrop.jpg");
}

.carouselDiv {
  max-height: 50em;
}

.homeText {
  text-align: center;
  margin-top: .5em;
  margin-bottom: 2.5em;
  padding-left: .5em;
  padding-right: .5em;
  font-size: 24px;
  font-family: sans-serif;
  font-style: italic;
  color: #2a2b23;
}
