.legends {
  font-size: 2em;
  position: absolute;
  top: 75%;
  left: 0;
  width: 100%;
  color: white;
}

.legends:hover {
  cursor: pointer;
  opacity: .75;
}
