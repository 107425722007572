.historyTitle {
  color: #2a2b23;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.titleContainer {
  margin-bottom: 1.5em;
}

.historyCard {
  padding-top: 4em;
  padding-bottom: 4em;
  padding-left: 2em;
  padding-right: 2em;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin-bottom: 5em;
  margin-top: 4em;
  color: #2a2b23;
  text-align: left;
}
